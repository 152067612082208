<template>
  <div
    class="finance-row"
  >
    <div style="display: flex; flex-direction: row;">
      <div style="flex-direction: column">
        <label>Dane zlecenia:</label>
      </div>
      <div><label>Zlecone:</label></div>
      <div><label>Kontakt:</label></div>
      <div><label>Oględziny:</label></div>
      <div><label>Wykonanie:</label></div>
      <div><label>Terminowość:</label></div>
      <div><label>Kwota netto:</label></div>
      <div class="comment-icon comment-icon-pointer" @click="$emit('edit-comment')">
        <i
          class="feather-message-circle"
          @click="$emit('edit-comment')"
          style="font-size: 20px;"
          :style="{color: `${item.data.comments && item.data.comments.length === 0 ? 'black' : '#80fd80'}`}"
        />
      </div>
    </div>
    <div style="display: flex; flex-direction: row;">
      <div>
        <div class="finance-row-number">
          {{ item.data.number }}
        </div>
        <div class="finance-row-orderType">
          {{ item.data.orderType }}
        </div>
        <div class="finance-row-registrationNumber">
          {{ item.data.registrationNumber }}
        </div>
        <div class="finance-row-make">
          {{ item.data.make }}
        </div>
        <div class="finance-row-model">
          {{ item.data.model }}
        </div>
      </div>
      <div class="finance-row-initialDate">
        {{ formatDate(item.data.initialDate) }}
      </div>
      <div class="finance-row-appointmentWithVictimAt">
        {{ formatDate(item.data.appointmentWithVictimAt) }}
      </div>
      <div class="finance-row-inspectionDate">
        {{ formatDate(item.data.inspectionDate) }}
      </div>
      <div class="finance-row-accomplishAt">
        {{ formatDate(item.data.accomplishAt) }}
      </div>
      <div class="finance-row-punctuality">
        {{ item.data.punctuality }}
      </div>
      <div
        class="finance-item-amount-section"
        :class="{ 'finance-item-amount': item['data']['invoiceId'] && canEditSettlementItem}"
        @click="() => { if (item.data.invoiceId && canEditSettlementItem) $emit('edit-amount') }"
      >
        <i
          v-if="item.data.invoiceId && canEditSettlementItem"
          class="feather-edit"
          title="Edytuj fakturę"
        /> {{ item.data.amount }} zł
      </div>
      <div class="comment-icon" />
    </div>
    <br>
    <div style="">
      <div><label>Powód opóźnienia:</label></div>
      <div class="finance-row-description finance-row-description-delay">
        {{ item.data.delay ? item.data.delay : '-' }}
      </div>
    </div>
    <div style="">
      <div><label>Opis faktury:</label></div>
      <div class="finance-row-description finance-row-description-text">
        {{ item.data.description ? item.data.description : '-' }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'FinanceRow',
  props: {
    item: {type: Object, default: () => ({data: {}})}
  },
  computed: {
    canEditSettlementItem() {
      return this.$store.state.base.securityActions['finance'].includes('put_settlement_item')
    }
  },
  methods: {
    formatDate(date) {
      return date.timestamp ? moment.unix(date.timestamp).format('YYYY-MM-DD') : ''
    }
  }
}
</script>

<style scoped>
  .finance-row div.comment-icon {
    width: 50px;
  }
  .comment-icon-pointer {
    cursor: pointer;
  }
.finance-row > div > div {
  margin: 0 10px;
  width: 14%;
}
  .finance-row .finance-row-description {
    padding: 0 1%;
    width: 100%;
  }
  .finance-item-amount {
    cursor: pointer;
  }
</style>
