<template>
  <div
    v-if="items"
    class="list-group"
  >
    <h3 class="tab-header">
      {{ headerName }}
    </h3>
    <div
      v-for="item in items"
      :key="item.uuid"
      class="list-group-item settlement-item"
      :style="`border-left: 3px solid ${itemTypeColor}`"
    >
      <FinanceRow
        v-if="item.data"
        :item="item"
        @edit-comment="editSettlementItemComment(item.data.id, item.data.comment, item.data.number, item.data.comments)"
        @edit-amount="editSettlementItemAmount(item.data.id, item.data.amount)"
      />
    </div>
  </div>
</template>

<script>
  import FinanceRow from './FinanceRow'
  import actionBlockingMixin from '../mixins/actionBlockingMixin'

  export default {
    name: 'SettlementItemsComponentVue',
    components: { FinanceRow },
    mixins: [
      actionBlockingMixin
    ],
    props: {
      items: {
        type: Array,
        default: () => []
      },
      service: {
        type: String,
        default: () => ''
      },
      headerName: {
        type: String,
        default: () => ''
      },
      itemTypeColor: {
        type: String,
        default: () => ('black')
      }
    },
    methods: {
      editSettlementItemComment (id, comment, uuid, comments) {
        if (this.getActionBlockade([true]).length === 0) {
          return
        }
        // TODO for later comments development
        this.$events.emit(`${this.service}:settlementItem:comment:edit`,
          { id, comment, uuid, comments })
      },
      editSettlementItemAmount (id, amount) {
        this.$events.emit(`${this.service}:settlementItem:amount:edit`,
          { id, amount })
      }
    }
  }
</script>

<style scoped>
  .settlement-item {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .list-group-margins {
    margin-left: 15px;
    margin-right: 15px;
  }
</style>
